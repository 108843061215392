<template>
  <div
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
    class="loginBackground"
  >
    <div class="loginSelection">
      <div class="loginSectionLeft">
        <h1 class="sectionTitle">Cannot Gain Access?</h1>
        <p class="sectionText">If you have forgotten your password, you can reset it here:</p>
        <button
          id="btnMoveLeft"
          class="sectionButton"
          style="margin: 0px 0px 30px 60px;"
          type="button"
          @click="moveLeft('reset')">
          <p>RESET PASSWORD</p>
        </button>
        <br>
        <p class="sectionText">If you do not have an account, you can request access:</p>
        <button
          id="btnMoveLeftBottom"
          class="sectionButton"
          style="margin: 0 0 0 60px;"
          type="button"
          @click="moveLeft('request')">
          <p style="width: 214px;">REQUEST ACCESS</p>
        </button>
      </div>
      <div
        class="loginSectionRight"
        style="vertical-align: top;"
      >
        <span class="sectionTitle">Log on to the icon portal</span>
        <p class="sectionText">Press the button below to return to the log on screen.</p>
        <button
          id="btnMoveRight"
          class="sectionButton"
          style="margin: 40px 0 0 60px;"
          type="button"
          @click="moveRight">
          <p>{{ verifyText }}</p>
        </button>
      </div>
    </div>
    <div class="loginPanel">
      <img
        :src="maintelLogo"
        :title="maintelLogoTitle"
        class="panelImage">
      <div class="loginPanelLeft">
        <span
          :style="loginColour"
          class="loginTitle"
        >{{ verifyText }}</span>
        <LogonComponent
          :class="{lopIsSelected:selected==='logon', lopIsNotSelected:selected==='fp'}"
          class="lopBox lopLogon"
          @VerifyCode="setVerify"
          @ForgottenPassword="setSelected('fp')"/>
      </div>
      <div class="loginPanelRightFP">
        <div
          :style="loginColour"
          class="loginFPTitle"
        >FORGOTTEN PASSWORD</div>
        <ForgottenPasswordRequestComponent
          :class="{lopIsSelected:selected==='fp', lopIsNotSelected:selected==='logon'}"
          class="lopBox lopForgottenPassword"
          @CancelRequest="setSelected('logon')"
          @ResetPassword="resetPassword()" />
      </div>
      <div class="loginPanelRightRA">
        <div
          :style="loginColour"
          class="loginFPTitle"
        >REQUEST ACCESS</div>
        <RequestAccessComponent
          :class="{lopIsSelected:selected==='fp', lopIsNotSelected:selected==='logon'}"
          class="lopBox lopForgottenPassword"
          @CancelRequest="setSelected('logon')"
          @RequestAccess="requestAccess()" />
      </div>
    </div>
  </div>
</template>

<script>
import LogonComponent from '../components/logon/logonComponent'
import ForgottenPasswordRequestComponent from '../components/logon/forgottenPasswordRequestComponent'
import RequestAccessComponent from '../components/logon/RequestAccessComponent'
import { AuthEventBus } from '../events/authEvents'
import { clearTimeout, setTimeout } from 'timers'
import { mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'icon portal - Login Page'
  },
  components: {
    LogonComponent,
    ForgottenPasswordRequestComponent,
    RequestAccessComponent
  },
  data () {
    return {
      timeoutSet: null,
      selected: '',
      showVerify: false
    }
  },
  computed: {
    loginColour () {
      return `color: ${this.$store.getters.loginButtonColour}`
    },
    loginBackgroundColour () {
      return `background-color: ${this.$store.getters.loginButtonColour}`
    },
    backgroundImage () {
      if (this.$store.getters.channelCompany.imageRoot === undefined) {
        return '/background.jpg'
      } else {
        return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.backgroundImage}`
      }
    },
    maintelLogo () {
      if (this.$store.getters.channelCompany.imageRoot === undefined) {
        return '/logo.png'
      } else {
        return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.siteLogo}`
      }
    },
    maintelLogoTitle () {
      if (this.$store.getters.channelCompany === undefined) {
        return 'Maintel Live Environment'
      } else {
        return this.$store.getters.channelCompany.name
      }
    },
    verifyText () {
      if (this.showVerify) {
        return 'VERIFY'
      } else {
        return 'LOGIN'
      }
    }
  },
  mounted () {
    this.setUsernameFocus(document.getElementById('txtUsername'))
    this.setNavLinks([])
  },
  created () {

  },
  methods: {
    ...mapActions([
      'setPage',
      'setNavLinks'
    ]),
    setVerify (val) {
      this.showVerify = val
    },
    setSelected (selectedItem) {
      this.selected = selectedItem
      AuthEventBus.$emit('set-logon-display', selectedItem)
    },
    moveLeft (moveType) {
      var txtResetPasswordUsername = document.getElementById('txtResetPasswordUsername')
      var loginPanel = document.getElementsByClassName('loginPanel')[0]
      var loginPanelLeft = document.getElementsByClassName('loginPanelLeft')[0]
      var loginPanelRightFP = document.getElementsByClassName('loginPanelRightFP')[0]
      var loginPanelRightRA = document.getElementsByClassName('loginPanelRightRA')[0]
      var loginSectionLeft = document.getElementsByClassName('loginSectionLeft')[0]
      var loginSectionRight = document.getElementsByClassName('loginSectionRight')[0]
      if (!loginPanel.classList.contains('moveLeft')) {
        if (loginPanel.classList.contains('moveRight')) {
          loginPanel.classList.remove('moveRight')
        }
        if (moveType === 'reset') {
          if (loginPanelRightFP.classList.contains('hidePanel')) {
            loginPanelRightFP.classList.remove('hidePanel')
            loginSectionRight.classList.remove('hidePanel')
          }
          loginPanelRightFP.classList.add('showPanel')
        }
        if (moveType === 'request') {
          if (loginPanelRightRA.classList.contains('hidePanel')) {
            loginPanelRightRA.classList.remove('hidePanel')
            loginSectionRight.classList.remove('hidePanel')
          }
          loginPanelRightRA.classList.add('showPanel')
        }
        loginSectionRight.classList.add('showPanel')
        if (loginPanelLeft.classList.contains('showPanel')) {
          loginPanelLeft.classList.remove('showPanel')
          loginSectionLeft.classList.remove('showPanel')
        }
        loginPanelLeft.classList.add('hidePanel')
        loginSectionLeft.classList.add('hidePanel')
        loginPanel.classList.add('moveLeft')
      }
      this.timeoutSet = setTimeout(() => this.setUsernameFocus(txtResetPasswordUsername), 1000)
    },
    moveRight () {
      var txtUsername = document.getElementById('txtUsername')
      var loginPanel = document.getElementsByClassName('loginPanel')[0]
      var loginPanelLeft = document.getElementsByClassName('loginPanelLeft')[0]
      var loginPanelRightFP = document.getElementsByClassName('loginPanelRightFP')[0]
      var loginPanelRightRA = document.getElementsByClassName('loginPanelRightRA')[0]
      var loginSectionLeft = document.getElementsByClassName('loginSectionLeft')[0]
      var loginSectionRight = document.getElementsByClassName('loginSectionRight')[0]
      if (!loginPanel.classList.contains('moveRight')) {
        if (loginPanel.classList.contains('moveLeft')) {
          loginPanel.classList.remove('moveLeft')
        }
        if (loginPanelLeft.classList.contains('hidePanel')) {
          loginPanelLeft.classList.remove('hidePanel')
          loginSectionLeft.classList.remove('hidePanel')
        }
        loginPanelLeft.classList.add('showPanel')
        loginSectionLeft.classList.add('showPanel')
        if (loginPanelRightFP.classList.contains('showPanel')) {
          loginPanelRightFP.classList.remove('showPanel')
          loginSectionRight.classList.remove('showPanel')
        }
        if (loginPanelRightRA.classList.contains('showPanel')) {
          loginPanelRightRA.classList.remove('showPanel')
          loginSectionRight.classList.remove('showPanel')
        }
        loginPanelRightFP.classList.add('hidePanel')
        loginPanelRightRA.classList.add('hidePanel')
        loginSectionRight.classList.add('hidePanel')
        loginPanel.classList.add('moveRight')
      }
      this.timeoutSet = setTimeout(() => this.setUsernameFocus(txtUsername), 1000)
    },
    setUsernameFocus (ctl) {
      ctl.focus()
      clearTimeout(this.timeoutSet)
    }
  }
}
</script>

<style>
  #txtUsername {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  #pwdPassword {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  #txtCode {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  #txtResetPasswordUsername, #requestAccessEmailAddress, #requestAccessLastName, #requestAccessFirstName, #requestAccessCompanyName {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  #pwdPasswordConfirm {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  #pwdValidateUserPassword {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  .loginBackground {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    background-size: cover;
    padding-top: 20vh;
    font-family: century-gothic, sans-serif;
  }
  .loginSelection {
    opacity: 0.9;
    background-color: #566373;
    height: 400px;
    width: 800px;
    padding: 0 20px;
    margin: auto;
  }
  .panelButton {
    border:none;
    padding:10px 30px;
    color: #fff;
  }
  .panelImage {
    margin: 20px auto;
    display: block;
  }
  .loginTitle {
    font-size: 2em;
    margin-left: 5px;
    font-weight: bold;
    margin-top:20px;
  }
  .loginFPTitle {
    font-size: 1.65em;
    font-weight: bold;
    margin: 0 0 20px 5px;
  }
  .sectionTitle {
    color: #fff;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin: 15px 0 30px 0;
  }
  .sectionText {
    color: #fff;
    line-height: 1.4em;
    font-size: 1em;
    margin-top: 20px;
  }
  .sectionButton {
    transform: skewX(-30deg);
    background-color: #566373;
    border: solid 2px #fff;
    margin: 40px 0 0 20px;
    cursor: pointer;
  }
  .sectionButton p {
    transform: skewX(30deg);
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    margin: 10px;
    width: 181px;
    line-height: 1.2em;
  }
  .loginSectionLeft {
    width: 360px;
    display: inline-block;
    padding: 20px 0 0 0;
  }
  .loginSectionRight {
    margin-left: 20px;
    width: 340px;
    display: inline-block;
    margin-top: 40px;
    padding: 40px 0 0 0;
  }
  .loginPanel {
    top: 16vh;
    left: calc(50vw);
    position: absolute;
    background-color: #fff;
    height: 480px;
    width: 400px;
    padding: 0 10px;
    -webkit-transition: left 1.5s ease-in-out;
    -moz-transition: left 1.5s ease-in-out;
    -ms-transition: left 1.5s ease-in-out;
    -o-transition: left 1.5s ease-in-out;
    transition: left 1.5s ease-in-out;
  }
  .loginPanelLeft {
    visibility: visible;
    opacity: 1;
    position: absolute;
    top: 135px;
    margin-left: 20px;
    width: 340px;
  }
  .loginPanelRightFP {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 120px;
    margin-left: 20px;
    width: 340px;
  }
  .loginPanelRightRA {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 120px;
    margin-left: 20px;
    width: 340px;
  }
  .moveLeft {
    left: calc(50vw - 440px);
  }
  .moveRight {
    left: 50vw;
  }
  .showPanel {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
    -moz-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
    -ms-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
    -o-transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
    transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .hidePanel {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    -moz-transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    -ms-transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    -o-transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

</style>
